import React from 'react'
import Layout from '../components/Layout'
import * as styles from '../styles/document.module.css'

export default function Countries(){
    return (
        <Layout>
            <section className={styles.document}>
                <div className="u-center-text u-margin-bottom-big">
                    <h2 className="heading-secondary">
                        The countries which accept an Apostille are
                    </h2>
                </div>
                <div className="row">
                    <div className="col-1-of-2">
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            A
                        </h3>
                        <p className="paragraph">
                            Albania <br/>
                            Andorra <br/>
                            Antigua and Barbuda <br/>
                            Argentina <br/>
                            Armenia <br/>
                            Austria <br/>
                            Australia <br/>
                            Azerbaijan
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            B
                        </h3>
                        <p className="paragraph">
                            Bahamas <br/>
                            Barbados <br/>
                            Belarus <br/>
                            Belgium <br/>
                            Belize <br/>
                            Bosnia and Herzegovina <br/>
                            Botswana <br/>
                            Brunei Darussalam <br/>
                            Bulgaria
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            C
                        </h3>
                        <p className="paragraph">
                            China (Hong Kong) <br/>
                            China (Macao) <br/>
                            Colombia <br/>
                            Cook Islands <br/>
                            Croatia <br/>
                            Cyprus <br/>
                            Czech Republic
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            D
                        </h3>
                        <p className="paragraph">
                            Denmark <br/>
                            Dominica <br/>
                            Dominican Republic
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            E
                        </h3>
                        <p className="paragraph">
                            Ecuador <br/>
                            El Salvador <br/>
                            Estonia
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            F
                        </h3>
                        <p className="paragraph">
                            Fiji <br/>
                            Finland <br/>
                            France <br/>
                            FYR of Macedonia
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            G
                        </h3>
                        <p className="paragraph">
                            Georgia <br/>
                            Germany <br/>
                            Greece <br/>
                            Grenada
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            H
                        </h3>
                        <p className="paragraph">
                            Honduras <br/>
                            Hong Kong <br/>
                            Hungary
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            I
                        </h3>
                        <p className="paragraph">
                            Iceland <br/>
                            India <br/>
                            Ireland <br/>
                            Israel <br/>
                            Italy
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            J
                        </h3>
                        <p className="paragraph">
                            Japan
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            K
                        </h3>
                        <p className="paragraph">
                            Kazakhstan <br/>
                            Korea, Republic of
                        </p>
                    </div>
                    <div className="col-1-of-2">
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            L
                        </h3>
                        <p className="paragraph">
                            Latvia <br/>
                            Lesotho <br/>
                            Liberia <br/>
                            Liechtenstein <br/>
                            Lithuania <br/>
                            Luxembourg
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            M
                        </h3>
                        <p className="paragraph">
                            Malawi <br/>
                            Macao (China) <br/>
                            Macedonia (FYR of) <br/>
                            Malta <br/>
                            Marshall Islands <br/>
                            Mauritius <br/>
                            Mexico <br/>
                            Monaco <br/>
                            Mongolia <br/>
                            Montenegro
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            N
                        </h3>
                        <p className="paragraph">
                            Namibia <br/>
                            Netherlands <br/>
                            New Zealand <br/>
                            Niue <br/>
                            Norway
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            P
                        </h3>
                        <p className="paragraph">
                            Panama <br/>
                            Poland <br/>
                            Portugal
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            R
                        </h3>
                        <p className="paragraph">
                            Republic of Moldova <br/>
                            Romania <br/>
                            Russian Federation
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            S
                        </h3>
                        <p className="paragraph">
                            Saint Kitts and Nevis <br/>
                            Saint Lucia <br/>
                            Saint Vincent and the Grenadines <br/>
                            Samoa <br/>
                            San Marino <br/>
                            Sao Tome and Principe <br/>
                            Serbia <br/>
                            Seychelles <br/>
                            Slovakia <br/>
                            Slovenia <br/>
                            South Africa <br/>
                            Spain <br/>
                            Suriname <br/>
                            Swaziland <br/>
                            Sweden <br/>
                            Switzerland
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            T
                        </h3>
                        <p className="paragraph">
                            The former Yugoslav Republic of Macedonia <br/>
                            Tonga <br/>
                            Trinidad and Tobago <br/>
                            Turkey
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            U
                        </h3>
                        <p className="paragraph">
                            Ukraine <br/>
                            United Kingdom of Great Britain and Northern Ireland (UK) <br/>
                            United States of America (USA)
                        </p>
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            V
                        </h3>
                        <p className="paragraph">
                            Vanuatu <br/>
                            Venezuela
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-1-of-2">
                        <p className="paragraph">
                            Many non-member countries started to accept a foreign document with an Apostille Certificate and then legalised at the Consular section of their own embassy in the foreign document’s country of origin.
                        </p>
                    </div>
                </div>
            </section>
        </Layout>
    )
}